<template>
<!-- sound icon <a href="http://www.freepik.com">Designed by macrovector / Freepik</a> -->
  <div>
    <v-container :fluid="true" id="archive-header">
      <v-row>
        <v-col cols="12">
          <div id="title-area">
            <p id="archive-title">Filed Rec. Sound Archive</p>
            <img src="../../assets/fieldrec-big.png" />
          </div>
        </v-col>
      </v-row>
      <hr style="border-color: #ccc" />
    </v-container>

    <el-container>
      <el-aside>
        <div style="padding-left: 12px;margin-top: 30px;">
          <v-card
            class="mx-auto"
            max-width="250"
            :rounded="true"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="license-card-overline">LICENSE</div>
                <v-list-item-subtitle class="license-card-title">
                  <span @click="onClickLicense">音源のご利用について</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
        <div class="side-menu">
          <el-card shadow="never">
            <el-tree 
              :data="areaTree"
              :props="defaultTree"
              :indent="24"
              :default-expand-all="true"
              :empty-text="''"
              @node-click="onClickAreaMenu"
            />
          </el-card>
        </div>
      </el-aside>
      <el-main style="padding: 12px 0 0 0;" v-loading="loading">
        <div id="archive-body">
          <div v-if="loading">
            <!-- <v-progress-circular
            indeterminate
            color="primary"
            style="position: absolute; top: 500px; left: 50%; width: 80px; height: 80px;"
          ></v-progress-circular> -->
          </div>
          <div v-else>
            <div v-for="area, areaIndex in areas" :key="areaIndex">
              <!-- 都道府県 -->
              <v-container :fluid="false">
                <div>
                  <v-row>
                    <v-col cols="10" :offset="1">
                      <p class="area-jp" :id="`area-${area.prefecture}`">{{ area.name }}</p>
                    </v-col>
                  </v-row>
                </div>
                <div class="area-eng">
                  <v-row>
                    <v-col cols="10" :offset="1">
                      <p>{{ area.prefecture }}</p>
                    </v-col>
                  </v-row>
                </div>
              </v-container>

              <!-- エリア -->
              <v-container :fluid="false" class="location-image" >
                <v-row>
                  <v-col xl="3" lg="3" md="4" sm="4" cols="6" class="map-image" 
                    v-for="(city, cityIndex) in area.cities"
                    :key="cityIndex"
                  >
                    <div class="area-city" :id="`area-${city.city}`">{{ city.name }}</div>
                    <div 
                      @mouseover="mouseOver(city.city)"
                      @mouseleave="mouseLeave(city.city)"
                      @click="onClickArea(area.prefecture, city)"
                      class="location-image-area"
                      :style="isHoveredManage[city.city] ? 'cursor: pointer;' : 'cursor: normal;'"
                    >
                      <img 
                        :src="require(`@/assets/city/${city.normalImage }`)"
                        v-if="isHoveredManage[city.city] == 'normal'"
                      />
                      <img 
                        :src="require(`@/assets/city/${city.activeImage}`)"
                        v-else
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>

    <v-dialog
      v-model="showLicenseDialog"
      width="600"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          License
        </v-card-title>

        <v-card-text>
          <div style="padding: 12px;">
            <p class="license-text">音源は商用・非商用のどちらでもご利用になれます。</p>
            <p>素材によっては加工がなされているものがあります。<br>表示されるアイコンを参照してください。</p>
            <div class="icon-description">
              <div class="icon-description-item">
                <img class="icon-item" src="@/icon/isNoiseReducted.png" width="35px" height="35px" />
                <p class="icon-item-title">ノイズリダクション</p>
                <p class="icon-item-text">
                  定在波や騒音が小さくなるように処理しています。特定の周波数帯が除去されていたり、ハイパスフィルタをかけたように低音が除去されているケースがあります。
                </p>
              </div>
              <div class="icon-description-item">
                <img class="icon-item" src="@/icon/isRepaired.png" width="35px" height="35px" />
                <p class="icon-item-title">リペア</p>
                <p class="icon-item-text">
                  機材トラブルによるクリックノイズや、雨・風による意図しないノイズを削除しています。
                </p>
              </div>
              <div class="icon-description-item">
                <img class="icon-item" src="@/icon/isNormalized.png" width="35px" height="35px" />
                <p class="icon-item-title">ノーマライズ</p>
                <p class="icon-item-text">
                  録音レベルが極端に低い音源は、音量をあげています。
                </p>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CityMasterApi from '@/api/cityMst.api';

export default {
  async created() {
    this.$set(this, 'loading', true);

    const response = await CityMasterApi.getCityMaster();
    const areas = await this.toJson(response.results);
    this.$set(this, 'areas', areas);

    this.setAsideMenu(response.results);

    this.$set(this, 'loading', false);
  },

  data() {
    return {
      archives: null,
      showLicenseDialog: false,
      hoverImage: {},
      prefectureData: {},
      loading: true,
      areas: [],
      areaTree: {},

      defaultTree: {
        children: 'children',
        label: 'label'
      },
      groupedAreaData: [],
      isHoveredManage: {},
    }
  },

  methods: {
    async toJson(response) {
      const data = [];
      response.forEach(city => {
        const cityData = {
          city: city.city,
          name: city.cityName,
          activeImage: city.activeImage,
          normalImage: city.normalImage
        };
        const index = data.findIndex(data => data.prefecture == city.prefecture);
        if(index < 0){
          data.push({
            prefecture: city.prefecture,
            name: city.prefName,
            cities: [cityData]
          });
        } else {
          data[index].cities.push(cityData);
        }
      });

      return data;
    },

    mouseOver(city) {
      this.$set(this.isHoveredManage, `${city}`,  'active');
    },

    mouseLeave(city){
      this.$set(this.isHoveredManage, `${city}`,  'normal');
    },

    onClickArea(prefecture, city) {
      this.$router.push({path: `archive/${prefecture}?city=${city.city}`, params: {cityName: city.name }});
    },

    onClickAreaMenu(node) {
      // 子要素がある場合はスクロールしない
      if(node.children) return;

      // 表示位置を取得
      const element = document.getElementById(`area-${node.id}`);
      const element_position = element.getBoundingClientRect();
      const divElement = document.getElementById('archive-body')
      // scroll
      divElement.scrollTo({ top: element_position.top - 333 });
    },

    setAsideMenu(areaData) {
      const menuData = [];
      const isHoveredManage = {};
      areaData.forEach(area => {
        const index = menuData.findIndex(menu => menu.id == area.prefecture);
        if(index < 0) {
          const data = {
            id: area.prefecture,
            label: area.prefName,
            children: [{
              id: area.city,
              label: area.cityName
            }]
          };
          menuData.push(data);
          isHoveredManage[area.city] = 'normal';
        } else {
          menuData[index].children.push({
            id: area.city,
            label: area.cityName
          })
          isHoveredManage[area.city] = 'normal';
        }
      })
      this.$set(this, 'isHoveredManage', isHoveredManage);
      this.$set(this, 'areaTree', menuData);
    },

    onClickLicense(){
      this.$set(this, 'showLicenseDialog', true)
    }
  }
}
</script>

<style scoped>
.v-list-item__content {
  padding: 8px;
}
.license-card-title {
  text-align: center;
  color: #666;
  font-family: Arial, Helvetica, sans-serif;
}
.license-card-title:hover {
  cursor: pointer;
}

.license-text {
  margin-top: 20px;
  font-size: 14px;
  color: rgb(150, 40, 40);
}

.icon-description {
  display: flex;
  flex-wrap: nowrap;
  align-items: start;
  margin-top: 20px;
}

.icon-description-item {
  flex-basis: 32%;
  padding: 12px;
  text-align: center;
}

.icon-item {
  text-align: center;
}

.icon-item-title{
  font-weight: bold;
  color: #666;
}

.icon-item-text {
  text-align: justify;
}

#archive-header {
  overflow-y: hidden;
  overflow-x: hidden;
}

#archive-body {
  height: calc(100vh - 245px);
  overflow-y: auto;
}

.side-menu >>> .el-tree-node__content {
  margin-top: 10px;
}

.side-menu >>> .el-tree-node__label {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

.side-menu >>> .el-card {
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-bottom: none;

  position: absolute;
  top: 370px;
  left: 80px;
}

#archive-title {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  height: 100px;
  width: 40%;
  line-height: 100px;
  font-weight: bold;
  font-family:Arial, Helvetica, sans-serif;
  color: black;
  background: radial-gradient(rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0.0) 100%); 
  backdrop-filter: blur(8px) contrast(120%) sepia(5%);
  /* filter: drop-shadow(0 0 100px red); */
}

@media screen and (max-width: 1920px) {
  #archive-title {
    font-size: 32px;
  }
}

@media screen and (max-width: 1200px) {
  #archive-title {
    font-size: 28px;
  }
}

@media screen and (max-width: 960px) {
  #archive-title {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  #archive-title {
    font-size: 15px;
  }
}

@media screen and (max-width: 480px) {
  #archive-title {
    font-size: 12px;
  }
}

#title-area img {
  height: 200px;
  width: 100%;
  /* object-position: 0% 80%; */
  object-position: 0% 50%;
  object-fit: cover;
}

.area-jp {
  font-size: 30px;
  font-weight: bold;
  font-family:Arial, Helvetica, sans-serif;
  color: #666;
}

.area-eng {
  margin-top: -64px;
  font-size: 28px;
  color: rgb(53, 44, 44);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding-left: 36px;
  letter-spacing: 4px;
  mix-blend-mode: difference; 
}

.area-city{
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: thin;
  font-family:Arial, Helvetica, sans-serif;
  color: #444;
}

.location-image-area:hover{
  cursor: pointer;
}

.location-image img:hover {
  width: 52%;
}

.location-image img {
  width: 50%;
  height: auto;
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
  transition: 0.3s;
  transition-timing-function: ease-out;
}

.map-image{
  text-align: center;
}

.map-image hr {
  width: 80%;
  text-align: center;
}

.loading {
  position: absolute;
  width: 50%;
  height: 300px;
}

</style>