<template>
  <div>
      <Archives />
  </div>
</template>

<script>
import Archives from '../components/Archives';

export default {
  name: 'Archive',
  components: {
    Archives
  },
}
</script>
